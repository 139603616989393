import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Progress,
    CardHeader,
    CardFooter,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput, FormSelect
} from "shards-react";
import {ESPLoader} from 'esp-web-flasher'

import {Storage} from 'aws-amplify';
import PageTitle from "../../components/common/PageTitle";
import SetupStage from "./programming-steps/Setup";
import MacEntryStage from "./programming-steps/MacEntry";
import ConnectForProgrammingStage from "./programming-steps/ConnectForProgramming";
import ProgrammingStage from "./programming-steps/Programming";
import ConnectForProgramingStage from "./programming-steps/ConnectForCommands";



function formatMacAddr(macAddr) {
    return macAddr
        .map((value) => value.toString(16).toUpperCase().padStart(2, "0"))
        .join(":");
}

const operatingMode =
    {
        SETUP:"Setup",
        MAC:"MAC",
        CONNECTING_PROG: "Connecting Prog",
        PROG: "Program",
        CONNECTING_TEST: "Connecting Test",
        TEST: "Test",
        COMPLETE: "Complete"
    };
class Programming extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            availableVersions: [],
            flashing: false,
            segments: 0,
            currentSegment: 0,
            segmentProgress: 0,
            segmentWeights: [],
            userText: "Please connect a device in bootloader mode then click connect",
            mode: operatingMode.SETUP
        };
        this.connectEspProg = this.connectEspProg.bind(this);
    }


    componentDidMount() {
        let versions = [];

        Storage.list('ci/') // for listing ALL files without prefix, pass '' instead
            .then(result => {
                result = result.filter(file => null !== file.key.match(/^((\w|\.|-)+\/)+flasher_args.json$/g));
                result.forEach(file => {
                    console.log(file)
                    let parts = file.key.split("/")
                    versions.push({key: file.key, version: parts[1], modified: file.lastModified});
                })
                let downloadResult = Promise.all(versions.map(version=>
                {
                    console.log(version.key)
                    return Storage.get(version.key, {download: true})
                }))
                downloadResult.then(res=>
                {
                    console.log(res)
                    console.log(JSON.stringify(res))
                    console.log(res[res.length-1])
                })
                versions = versions.sort((a,b)=>(a.version > b.version)?-1:1);
                this.setState(ps => {
                    return {availableVersions: versions, loading: false}
                })
            })
            .catch(err => {
                console.log(err)
            });
    }

    async connectEspProg() {
        this.setState(ps => {
            ps.mode = operatingMode.CONNECTING_PROG;
            return ps;
        });
        try {

            // - Request a port and open a connection.
            let options = {filters:[{usbVendorId: 12346, usbProductId: 2}]};
            const port = await navigator.serial.requestPort(options);
            let portInfo = await port.getInfo();
            console.log(JSON.stringify(portInfo))
            const logger = {
                log: (...args) => console.log(...args),
                debug: (...args) => console.log(...args),
                error: (...args) => console.log(...args),
            }

            logger.log("Connecting...");
            await port.open({ baudRate: 115200 });

            logger.log("Connected successfully.");

            const esploader = new ESPLoader(port, logger);
            await esploader.initialize();

            console.log("Connected to " + esploader.chipName);
            console.log("MAC Address: " + formatMacAddr(esploader.macAddr()));

            let stub = await esploader.runStub();
            this.setState(ps => {
                ps.mode = operatingMode.PROG;
                ps.macAddress = formatMacAddr(esploader.macAddr());
                ps.chipType = esploader.chipName;
                ps.espLoader = esploader;
                ps.espStub = stub;
                ps.port = port;
                return ps;
            })
        } catch (e) {
            console.log(e)
            this.setState(ps => {
                ps.mode = operatingMode.MAC;
                ps.userText = "Failed to connect";
                return ps;
            });
        }
    }

    async flashFirmware(fw) {
        let basePath = fw.key.split("/").slice(0, -1).join("/") + "/";
        console.log(basePath)
        let flashArgsObject = await Storage.get(fw.key, {download: true});
        let flashArgsRaw = await flashArgsObject.Body.text();
        let flashArgs = JSON.parse(flashArgsRaw);
        let segmentsToWrite = []
        let flashSuccess = false;
        console.log(flashArgs)
        for (const key of Object.keys(flashArgs.flash_files)) {
            console.log(Number(key) + "->" + flashArgs.flash_files[key])
            let blobObject = await Storage.get(basePath + flashArgs.flash_files[key], {download: true})
            let blob = await (await blobObject.Body).arrayBuffer();
            segmentsToWrite.push({offset: Number(key), buffer: blob})
        }
        let totalSize = segmentsToWrite.reduce((partialSum, segment) => partialSum + segment.buffer.byteLength, 0);
        this.setState(ps => {
            ps.flashing = true;
            ps.segments = segmentsToWrite.length;
            ps.flashingDone = false;
            ps.segmentWeights = segmentsToWrite.map(segment=>segment.buffer.byteLength/totalSize);
            return ps;
        })
        try {
            for(const segment of segmentsToWrite)
            {
                this.setState(ps => {
                    ps.segmentProgress = 0;
                    ps.currentSegment = segmentsToWrite.indexOf(segment);
                    return ps;
                })
                await this.state.espStub.flashData(
                    segment.buffer,
                    (bytesWritten) => {
                        this.setState(ps => {
                            ps.segmentProgress = Math.min(bytesWritten / segment.buffer.byteLength, 1)
                            return ps;
                        })
                    },
                    segment.offset
                );
                flashSuccess = true;
            }
        } catch (e) {
            console.log("Flashing failed error:")
            console.log(e)
        }

        /* below is a strange process which leads to the com port being closed,
        this allows the port to be reused with refreshing
        espLoader.disconnect() does not actually close the port but reconfigurePort does but it then tries to reopen it,
        providing an invalid baud rate causes it to close the old port,
        but it is unable to reopen the port due to the invalid baud */

        await this.state.espLoader.disconnect();
        if(this.state.port !== undefined) this.state.port.close();

        this.setState(ps => {
            ps.flashing = false;
            ps.segments = 0;
            ps.currentSegment = 0;
            ps.segmentProgress = 0;
            ps.segmentWeights = [];
            ps.mode = flashSuccess===true?operatingMode.TEST:operatingMode.MAC;
            ps.macAddress = "";
            ps.chipType = "";
            ps.espLoader = undefined;
            ps.espStub = undefined;
            ps.userText = flashSuccess===true?"Programing complete, please connect next device in bootloader":"PROGRAMMING FAILED"
            return ps;
        })
        console.log("Done")
    }

    FlashingProgress = (props) =>
    {
        if(this.state.flashing)
        {
            let progress = 0;
            this.state.segmentWeights.forEach((weight, index)=>
            {
                if(index < this.state.currentSegment)
                {
                    progress += 100*weight;
                }
                else if(index === this.state.currentSegment)
                {
                    progress += 100*weight*this.state.segmentProgress;
                }
            });
            return (
                <div>
                    <Progress theme="primary" value={progress} />
                    <br/>
                    Flashing...
                </div>
            );
        }
        else if(this.state.flashingDone)
        {
            return (
                <div>
                    <Progress theme="primary" value={100}/>
                    <br/>
                    Flashing complete
                </div>
            );
        }
        else
        {
            return <div/>;
        }
    }

    render() {
        const {
            availableVersions,
            flashing,
            mode
        } = this.state;

        switch (mode)
        {
            case operatingMode.SETUP:
                return (
                    <SetupStage handleSuccess={(worksOrder)=>
                    {
                        console.log("Setup Success (" + worksOrder + ") moving to mac step");
                        this.setState(ps => {
                            ps.mode = operatingMode.MAC;
                            return ps;
                        })
                    }
                    }/>);
            case operatingMode.MAC:
                return (
                    <MacEntryStage handleSuccess={(mac)=>
                    {
                        console.log("MAC supplied: (" + mac + ")");
                        this.setState(ps => {
                            ps.mode = operatingMode.CONNECTING_PROG;
                            return ps;
                        })
                    }
                    }/>);
            case operatingMode.CONNECTING_PROG:
                return (
                    <ConnectForProgrammingStage handleSuccess={(port, esploader, stub, mac)=>
                    {
                        console.log("ESP MAC: (" + mac + ")");
                        this.setState(ps => {
                            ps.port = port;
                            ps.esploader = esploader;
                            ps.stub = stub;
                            ps.espMac = mac;
                            ps.mode = operatingMode.PROG;
                            return ps;
                        })
                    }
                    }/>);
            case operatingMode.PROG:
                return (
                    <ProgrammingStage port={this.state.port} esploader={this.state.esploader} stub={this.state.stub}  handleSuccess={(mac)=>
                    {
                        console.log("MAC supplied: (" + mac + ")");
                        this.setState(ps => {
                            ps.mode = operatingMode.CONNECTING_TEST;
                            return ps;
                        })
                    }
                    }/>);
            case operatingMode.CONNECTING_TEST:
                return (
                    <ConnectForProgramingStage handleSuccess={(port)=>
                    {
                        console.log("Connected for test: (" + port.getInfo() + ")");
                        this.setState(ps => {
                            ps.mode = operatingMode.CONNECTING_TEST;
                            return ps;
                        })
                    }
                    }/>);
            case operatingMode.TEST:
                return (
                    <Container fluid className="main-content-container px-4">
                    </Container>);
            case operatingMode.COMPLETE:
                return (
                    <Container fluid className="main-content-container px-4">
                    </Container>);
        }

        if (mode === operatingMode.PROG) {

            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Programming"} subtitle="Production"
                                   className="text-sm-left"/>
                    </Row>
                    <Row>
                        <Col lg="3" md="6" sm="12" className="mb-4">
                            <Card small className="blog-comments">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Attached Device</h6>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <div className="blog-comments__item d-flex p-3">
                                        <div className="blog-comments__content">
                                            <p>
                                                <span
                                                    className="text-mutes"> MAC Address: {this.state.macAddress}</span>
                                            </p>
                                            <p>
                                                <span className="text-mutes"> Chip Type: {this.state.chipType}</span>
                                            </p>
                                            <div>
                                            <span className="text-mutes">
                                                <this.FlashingProgress/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* First Row of Devices */}
                    <Row>
                        <Col lg="3" md="6" sm="12" className="mb-4">
                            <Card small className="blog-comments">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Available Firmware Versions</h6>
                                </CardHeader>

                                <CardBody className="p-0">
                                    {availableVersions.slice(0,1).map((fw, idx) => (
                                        <div key={idx} className="blog-comments__item d-flex p-3">
                                            {/* Avatar */}

                                            {/* Content */}
                                            <div className="blog-comments__content">
                                                {/* Content :: Title */}
                                                <div className="blog-comments__meta text-mutes">
                                                    <b>{fw.version}</b>
                                                </div>

                                                {/* Content :: Body */}
                                                <p>
                                                    <span
                                                        className="text-mutes"> Made available {fw.modified.toString()}</span>
                                                </p>
                                                <Button theme="white" disabled={flashing}
                                                        onClick={() => this.flashFirmware(fw)}>
                                                    Flash to local device
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            );
        } else if (mode === operatingMode.SETUP) {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Programming"} subtitle="Production"
                                   className="text-sm-left"/>
                    </Row>
                    <Row>
                        <h2>
                            Setup programming parameters
                        </h2>
                    </Row>
                    <Row>
                        <h2>
                            <Form>
                                <FormGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>WO/</InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput placeholder="Works Order" />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput
                                        type="password"
                                        placeholder="Password"
                                        value="myCoolPassword"
                                        onChange={() => {}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormInput
                                        placeholder="1234 Main St"
                                        value="7898 Kensington Junction, New York, USA"
                                        onChange={() => {}}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md="7">
                                        <FormInput value="New York" onChange={() => {}} />
                                    </Col>
                                    <Col md="5" className="form-group">
                                        <FormSelect>
                                            <option>Choose ...</option>
                                            <option>...</option>
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Form>
                        </h2>
                    </Row>
                    <Row noGutters className="page-header py-4">
                        <ButtonGroup vertical>
                            <Button disabled={mode === operatingMode.CONNECTING_PROG} onClick={this.connectEspProg}>Next</Button>
                        </ButtonGroup>
                    </Row>
                </Container>
            );
        }  else if (mode === operatingMode.CONNECTING_PROG) {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Programming"} subtitle="Production"
                                   className="text-sm-left"/>
                    </Row>
                    <Row>
                        <h2>
                            Connecting
                        </h2>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Programming"} subtitle="Production"
                                   className="text-sm-left"/>
                    </Row>
                    <Row>
                        <h2>
                            {this.state.userText}
                        </h2>
                    </Row>
                    <Row>
                        <h2>
                            <Form>
                                <FormGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>WO/</InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput placeholder="Works Order" />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <FormInput
                                        type="password"
                                        placeholder="Password"
                                        value="myCoolPassword"
                                        onChange={() => {}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormInput
                                        placeholder="1234 Main St"
                                        value="7898 Kensington Junction, New York, USA"
                                        onChange={() => {}}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md="7">
                                        <FormInput value="New York" onChange={() => {}} />
                                    </Col>
                                    <Col md="5" className="form-group">
                                        <FormSelect>
                                            <option>Choose ...</option>
                                            <option>...</option>
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Form>
                        </h2>
                    </Row>
                    <Row noGutters className="page-header py-4">
                        <ButtonGroup vertical>
                            <Button disabled={mode === operatingMode.CONNECTING_PROG} onClick={this.connectEspProg}>Connect</Button>
                        </ButtonGroup>
                    </Row>
                </Container>
            );
        }
    }
}

export default Programming;
