/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDeviceAlt = /* GraphQL */ `
  query GetDeviceAlt($id: ID!) {
    getDeviceAlt(id: $id) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        CustomBranding
      }
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      MACAddress
      Name
      CurrentState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      PendingState {
        CheckInSchedule {
          Type
          Datum
          Period
          CronString
        }
        FirmwareVersion
        Status
        BatteryStatus
        DigestSettings {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        Type
        Probes {
          SN
          Type
          SamplePeriod
          Calibrations {
            Timestamp
            Expiry
          }
        }
        Channels {
          Name
          Type
          Resolution
          AlarmSettings {
            Type
            CustomMessage
            CustomExitMessage
            Direction
            Level
            KnockInDelay
            KnockOutDelay
            Hysteresis
          }
          RenderSettings {
            Unit
            ScaleFactor
            Offset
          }
        }
        TrendConfig {
          Period
          Samples
          AggregationMechanism
        }
        PreRollConfig {
          Period
          Samples
          AggregationMechanism
        }
        SetupTime
        LastCommsTime
        LastConfigChangeTime
      }
      LatestReadings {
        ChannelIdx
        Readings {
          Value
          Min
          Max
          Timestamp
        }
      }
      Events {
        Type
        Source
        Timestamp
        Channel
        AlarmSetting
        Readings {
          Value
          Min
          Max
          Timestamp
        }
        LatestReadings {
          Value
          Min
          Max
          Timestamp
        }
        Processed
      }
      Digests {
        DigestSetting {
          Type
          Channel
          Schedule {
            Type
            Datum
            Period
            CronString
          }
          Receivers
        }
        ChannelDigests {
          StartTimestamp
          EndTimestamp
          MinValue
          AvgValue
          MaxValue
          DurationInAlarm
          AlarmsEntered
          AlarmsExited
        }
        DeviceDigest {
          StartTimestamp
          EndTimestamp
          Uptime
          HeapFreeLWM
          BatteryConsumed
        }
        TimeSubmitted
      }
      Spectators
      Maintainers
      Tags
      EmailSettings {
        DigestEnabled
        CustomBranding
      }
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          CustomBranding
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        PendingState {
          CheckInSchedule {
            Type
            Datum
            Period
            CronString
          }
          FirmwareVersion
          Status
          BatteryStatus
          DigestSettings {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          Type
          Probes {
            SN
            Type
            SamplePeriod
            Calibrations {
              Timestamp
              Expiry
            }
          }
          Channels {
            Name
            Type
            Resolution
            AlarmSettings {
              Type
              CustomMessage
              CustomExitMessage
              Direction
              Level
              KnockInDelay
              KnockOutDelay
              Hysteresis
            }
            RenderSettings {
              Unit
              ScaleFactor
              Offset
            }
          }
          TrendConfig {
            Period
            Samples
            AggregationMechanism
          }
          PreRollConfig {
            Period
            Samples
            AggregationMechanism
          }
          SetupTime
          LastCommsTime
          LastConfigChangeTime
        }
        LatestReadings {
          ChannelIdx
          Readings {
            Value
            Min
            Max
            Timestamp
          }
        }
        Events {
          Type
          Source
          Timestamp
          Channel
          AlarmSetting
          Readings {
            Value
            Min
            Max
            Timestamp
          }
          LatestReadings {
            Value
            Min
            Max
            Timestamp
          }
          Processed
        }
        Digests {
          DigestSetting {
            Type
            Channel
            Schedule {
              Type
              Datum
              Period
              CronString
            }
            Receivers
          }
          ChannelDigests {
            StartTimestamp
            EndTimestamp
            MinValue
            AvgValue
            MaxValue
            DurationInAlarm
            AlarmsEntered
            AlarmsExited
          }
          DeviceDigest {
            StartTimestamp
            EndTimestamp
            Uptime
            HeapFreeLWM
            BatteryConsumed
          }
          TimeSubmitted
        }
        Spectators
        Maintainers
        Tags
        EmailSettings {
          DigestEnabled
          CustomBranding
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
