import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Progress, CardHeader, CardFooter, Button, ButtonGroup
} from "shards-react";
import {connect} from 'esp-web-flasher'
import PageTitle from "../components/common/PageTitle";

import {Storage} from 'aws-amplify';



function formatMacAddr(macAddr) {
    return macAddr
        .map((value) => value.toString(16).toUpperCase().padStart(2, "0"))
        .join(":");
}

function timeout (ms) {
    return new Promise(res => setTimeout(res,ms));
}

const operatingMode =
    {
        DISCONNECTED: "Disconnected",
        CONNECTING: "Connecting",
        PROG: "Program",
        CMD: "Command"
    };

const metadataFieldNames =
    {
        0: "sn",
        1: "resolution",
        2: "maxR",
        3: "minR",
        4: "steinhartA",
        5: "steinhartB",
        6: "steinhartC",
        7: "manufacturingData",
        9: "type",
        10: "uid",
        11: "noChannels",
        12: "measurementType",
        13: "dib",
        14: "majorFirmwareVersion",
        15: "minorFirmwareVersion",
        16: "firmwareReleaseType"
    }
class LocalDevice extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            availableVersions: [],
            flashing: false,
            segments: 0,
            currentSegment: 0,
            segmentProgress: 0,
            segmentWeights: [],
            mode: operatingMode.DISCONNECTED
        };
        this.connectEspProg = this.connectEspProg.bind(this);
        this.connectEspCmd = this.connectEspCmd.bind(this);
        this.sendCommand = this.sendCommand.bind(this);
    }


    componentDidMount() {
        let versions = [];

        Storage.list('ci/') // for listing ALL files without prefix, pass '' instead
            .then(result => {
                result = result.filter(file => null !== file.key.match(/^((\w|\.|-)+\/)+flasher_args.json$/g));
                result.forEach(file => {
                    console.log(file)
                    let parts = file.key.split("/")
                    versions.push({key: file.key, version: parts[1], modified: file.lastModified});
                })
                versions = versions.sort((a,b)=>(a.version > b.version)?-1:1);
                this.setState(ps => {
                    return {availableVersions: versions, loading: false}
                })
            })
            .catch(err => {
                console.log(err)
            });
    }

    async sendCommand(command)
    {
        let port = this.state.port;
        const writer = port.writable.getWriter();
        await writer.write(new TextEncoder().encode(command + "\n\r"));
        writer.releaseLock();
        //const reader = textDecoder.readable.getReader();
        let text = "";
        const TIMEOUT = 1000;
        const reader = port.readable.getReader();
        const decoder = new TextDecoder();
        try {
            while (true) {
                let  {value, done} = await Promise.race([
                    reader.read(),
                    new Promise((_, reject) => setTimeout(reject, TIMEOUT, new Error("timeout")))
                ]);
                if (done) {
                    // Allow the serial port to be closed later.
                    reader.releaseLock();
                    break;
                }
                if (value) {
                    text += decoder.decode(value);
                }
            }
        } catch (error) {
            /* Catch timeout and cancel reader to trigger lock release */
            await reader.cancel("TIMEOUT")
        }
        text = text.substring(text.indexOf(command) + command.length)
        return text.trim();
    }

    async connectEspProg() {
        this.setState(ps => {
            ps.mode = operatingMode.CONNECTING;
            return ps;
        });
        const esploader = await connect({
            log: (...args) => console.log(...args),
            debug: (...args) => console.log(...args),
            error: (...args) => console.log(...args),
        });
        try {
            await esploader.initialize();

            console.log("Connected to " + esploader.chipName);
            console.log("MAC Address: " + formatMacAddr(esploader.macAddr()));

            let stub = await esploader.runStub();
            this.setState(ps => {
                ps.mode = operatingMode.PROG;
                ps.macAddress = formatMacAddr(esploader.macAddr());
                ps.chipType = esploader.chipName;
                ps.espLoader = esploader;
                ps.espStub = stub;
                return ps;
            })
        } catch (e) {
            console.log(e)
            this.setState(ps => {
                ps.mode = operatingMode.DISCONNECTED;
                return ps;
            });
        }
    }

    async connectEspCmd() {
        this.setState(ps => {
            ps.mode = operatingMode.CONNECTING;
            return ps;
        });
        try {
            const portList = await navigator.serial.getPorts()
            console.log(portList)
            const port = await navigator.serial.requestPort()
            await port.open({baudRate:115200});
            this.setState(ps => {
                ps.port = port;
                return ps;
            });

            this.setState(ps => {
                ps.mode = operatingMode.CMD;
                return ps;
            });

        } catch (e) {
            console.log(e)
            this.setState(ps => {
                ps.mode = operatingMode.DISCONNECTED;
                return ps;
            });
        }
    }

    async flashFirmware(fw) {
        let basePath = fw.key.split("/").slice(0, -1).join("/") + "/";
        console.log(basePath)
        let flashArgsObject = await Storage.get(fw.key, {download: true});
        let flashArgsRaw = await flashArgsObject.Body.text();
        let flashArgs = JSON.parse(flashArgsRaw);
        let segmentsToWrite = []
        console.log(flashArgs)
        for (const key of Object.keys(flashArgs.flash_files)) {
            console.log(Number(key) + "->" + flashArgs.flash_files[key])
            let blobObject = await Storage.get(basePath + flashArgs.flash_files[key], {download: true})
            let blob = await (await blobObject.Body).arrayBuffer();
            segmentsToWrite.push({offset: Number(key), buffer: blob})
        }
        let totalSize = segmentsToWrite.reduce((partialSum, segment) => partialSum + segment.buffer.byteLength, 0);
      this.setState(ps => {
        ps.flashing = true;
        ps.segments = segmentsToWrite.length;
        ps.flashingDone = false;
        ps.segmentWeights = segmentsToWrite.map(segment=>segment.buffer.byteLength/totalSize);
        return ps;
      })
        for(const segment of segmentsToWrite)
        {
            this.setState(ps => {
                ps.segmentProgress = 0;
                ps.currentSegment = segmentsToWrite.indexOf(segment);
                return ps;
            })
          await this.state.espStub.flashData(
              segment.buffer,
              (bytesWritten) => {
                  this.setState(ps => {
                      ps.segmentProgress = Math.min(bytesWritten / segment.buffer.byteLength, 1)
                      return ps;
                  })
              },
              segment.offset
          );
        }
      this.setState(ps => {
        ps.flashing = false;
        ps.flashingDone = true;
        return ps;
      })
      console.log("Done")
    }

    async probeMetaDataRead(rawMeta)
    {
        console.log(rawMeta)
        let metaLines = rawMeta.split("\n")
        metaLines = metaLines.map(line=>line.trim().split(","))
        console.log(metaLines)
        let metadata = {};
        metaLines.forEach(line=>
        {
            if(line.length === 2)
            {
                if(metadataFieldNames[Number(line[0])])
                {
                    metadata[metadataFieldNames[Number(line[0])]] = Number(line[1])
                }
            } else if(line.length === 3)
            {
                if(metadataFieldNames[Number(line[0])])
                {
                    if(undefined === metadata.channels)
                    {
                        metadata.channels = []
                    }
                    if(undefined === metadata.channels[Number(line[1])-1])
                    {
                        metadata.channels[Number(line[1])-1] = {};
                    }
                    metadata.channels[Number(line[1])-1][metadataFieldNames[Number(line[0])]] = Number(line[2])
                }
            }
        })
        console.log(metadata)
    }

    FlashingProgress = (props) =>
    {
        if(this.state.flashing)
        {
            let progress = 0;
            this.state.segmentWeights.forEach((weight, index)=>
            {
                if(index < this.state.currentSegment)
                {
                    progress += 100*weight;
                }
                else if(index === this.state.currentSegment)
                {
                    progress += 100*weight*this.state.segmentProgress;
                }
            });
            return (
                <div>
                    <Progress theme="primary" value={progress} />
                    <br/>
                    Flashing...
                </div>
            );
        }
        else if(this.state.flashingDone)
        {
            return (
                <div>
                    <Progress theme="primary" value={100}/>
                    <br/>
                    Flashing complete
                </div>
            );
        }
        else
        {
            return <div/>;
        }
    }

    render() {
        const {
            availableVersions,
            flashing,
            mode
        } = this.state;
        if (mode === operatingMode.PROG) {

            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Local Device Actions"} subtitle="Local Device"
                                   className="text-sm-left"/>
                    </Row>
                    <Row>
                        <Col lg="3" md="6" sm="12" className="mb-4">
                            <Card small className="blog-comments">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Attached Device</h6>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <div className="blog-comments__item d-flex p-3">
                                        <div className="blog-comments__content">
                                            <p>
                                                <span
                                                    className="text-mutes"> MAC Address: {this.state.macAddress}</span>
                                            </p>
                                            <p>
                                                <span className="text-mutes"> Chip Type: {this.state.chipType}</span>
                                            </p>
                                            <div>
                                            <span className="text-mutes">
                                                <this.FlashingProgress/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* First Row of Devices */}
                    <Row>
                        <Col lg="3" md="6" sm="12" className="mb-4">
                            <Card small className="blog-comments">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Available Firmware Versions</h6>
                                </CardHeader>

                                <CardBody className="p-0">
                                    {availableVersions.map((fw, idx) => (
                                        <div key={idx} className="blog-comments__item d-flex p-3">
                                            {/* Avatar */}
                                            {/* <div className="blog-comments__avatar mr-3">
                              <img
                                  src={EventIcons[event.Type] ? EventIcons[event.Type] : EventIcons.DEFAULT}
                                  alt={""}/>
                            </div>*/}

                                            {/* Content */}
                                            <div className="blog-comments__content">
                                                {/* Content :: Title */}
                                                <div className="blog-comments__meta text-mutes">
                                                    <b>{fw.version}</b>
                                                </div>

                                                {/* Content :: Body */}
                                                <p>
                                                    <span
                                                        className="text-mutes"> Made available {fw.modified.toString()}</span>
                                                </p>
                                                <Button theme="white" disabled={flashing}
                                                        onClick={() => this.flashFirmware(fw)}>
                                                    Flash to local device
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            );
        } else if (mode === operatingMode.CMD) {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Local Device Actions"} subtitle="Local Device"
                                   className="text-sm-left"/>
                    </Row>
                    <Row noGutters className="page-header py-4">
                        <ButtonGroup vertical>
                            <Button onClick={async ()=> alert(await this.sendCommand("help"))}>Help</Button>
                            <Button onClick={async ()=> this.probeMetaDataRead(await this.sendCommand("meta"))}>Meta</Button>
                        </ButtonGroup>
                    </Row>
                </Container>
            );
        } else if (mode === operatingMode.CONNECTING) {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Local Device Actions"} subtitle="Local Device"
                                   className="text-sm-left"/>
                    </Row>
                    <Row noGutters className="page-header py-4">
                        <ButtonGroup vertical>
                            <Button disabled={true}>Connect for
                                programming</Button>
                            <Button disabled={true}>Connect for
                                commands</Button>
                        </ButtonGroup>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title={"Local Device Actions"} subtitle="Local Device"
                                   className="text-sm-left"/>
                    </Row>
                    <Row noGutters className="page-header py-4">
                        <ButtonGroup vertical>
                            <Button onClick={this.connectEspProg}>Connect for
                                programming</Button>
                            <Button onClick={this.connectEspCmd}>Connect for
                                commands</Button>
                        </ButtonGroup>
                    </Row>
                </Container>
            );
        }
    }
}

export default LocalDevice;
