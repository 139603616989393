import React from "react";
import OwnDevices from "../views/OwnDevices";
import Device from "../views/Device";
import LocalDevice from "../views/LocalDevice";
import Errors from "../views/Errors";
import Programming from "../views/production/Programming";

const sidebarNavItems = function() {
  return [
    {
      title: "Your Devices",
      htmlBefore: '<i class="material-icons">smartphone</i>',
      to: "/own-devices",
      component: OwnDevices,
      requiresGroup: ["user", "admin"]
    },
    {
      title: "Local Device",
      htmlBefore: '<i class="material-icons">usb</i>',
      to: "/local-device",
      component: LocalDevice,
      requiresGroup: ["user", "admin"]
    },
    {
      to: "/device/:id",
      component: Device,
      requiresGroup: ["user", "admin"]
    },
    {
      title: "Programming",
      htmlBefore: '<i class="material-icons">smartphone</i>',
      to: "/production/programming",
      component: Programming,
      requiresGroup: ["production"]
    },
    {
      title: "Calibration",
      htmlBefore: '<i class="material-icons">usb</i>',
      to: "/production/calibration",
      requiresGroup: ["production"]
    },
    {
      path: "/errors",
      component: Errors
    }/*,
    {
      title: "Demo Graphs",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors",
    },*/
  ];
}

export default sidebarNavItems;
