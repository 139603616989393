import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col
} from "shards-react";
import FirmwareActions from "./actions/FirmwareActions";
import ChannelActions from "./actions/ChannelActions";
import AlarmSettingActions from "./actions/AlarmSettingActions";
import DigestActions from "./actions/DigestActions";
import ErrorBoundary from "../ErrorBoundary";
import NameActions from "./actions/NameActions";
import CheckInActions from "./actions/CheckInActions";

const DeviceActions = ({title, actions, device, refreshDevice}) => (
  <Card small className="blog-comments">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      {actions.map((action, idx) => (
        <div key={idx} className="blog-comments__item d-flex p-3">

          {/* Content */}
          <div className="blog-comments__content">
            <ErrorBoundary customMessage={"Failed to render " + action.title}>
              <action.body device={device} refreshDevice={refreshDevice}/>
            </ErrorBoundary>
          </div>
        </div>
      ))}
    </CardBody>

    <CardFooter className="border-top">
      <Row>
        <Col className="text-center view-report">
          <Button theme="white" type="submit">
            Previous
          </Button>
          <Button theme="white" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </CardFooter>
  </Card>
);

DeviceActions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The events dataset.
   */
  actions: PropTypes.array
};

DeviceActions.defaultProps = {
  title: "Actions",
  actions: [
    NameActions,
    FirmwareActions,
    ChannelActions,
    AlarmSettingActions,
    DigestActions,
    CheckInActions
  ]
};

export default DeviceActions;
