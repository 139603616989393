import React from "react";
import {Button, ButtonGroup, Modal, ModalHeader, ModalBody, FormGroup, FormInput, Form} from "shards-react";
import {API} from 'aws-amplify';
import {
  updateRenderSettings,
    updateChannelName
} from "../../../graphql/mutations";

const RenderSettingPresets =
  {
    "TEMPERATURE":
      [

        {
          Name: "Fahrenheit",
          RenderSettings:
            {
              Unit: "°F",
              ScaleFactor: 9/5,
              Offset: 32
            }
        },
        {
          Name: "Celsius",
          RenderSettings:
            {
              Unit: "°C",
              ScaleFactor: 1,
              Offset: 0
            }
        },
        {
          Name: "Kelvin",
          RenderSettings:
            {
              Unit: "K",
              ScaleFactor: 1,
              Offset: 273.15
            }
        }
      ],
    "HUMIDITY":
      [
        {
          Name: "%RH",
          RenderSettings:
            {
              Unit: "%RH",
              ScaleFactor: 1,
              Offset: 0
            }
        }
      ]
  }

class ChannelActionsMenu extends React.Component {
  constructor(props) {
    super(props);
      let state =
          {
              renderSettingModalOpen: [],
              editNameModalOpen: [],
              channelNames:[]
          }
    props.device.CurrentState.Channels.forEach((channel, idx)=>
    {
        state.renderSettingModalOpen[idx] = false
        state.editNameModalOpen[idx] = false
    })
    this.state = state;
    this.handleRenderSettingsChange = this._handleRenderSettingsChange.bind(this);
    this.handleApplyNewRenderSettings = this._handleApplyNewRenderSettings.bind(this);
    this.handleNameChanged = this._handleNameChanged.bind(this);
    this.changeChannelName = this._changeChannelName.bind(this);
  }

  _handleRenderSettingsChange(channelIdx)
  {
    this.setState(ps=>{ps.renderSettingModalOpen[channelIdx] = !ps.renderSettingModalOpen[channelIdx]; return ps})
  }

  _handleApplyNewRenderSettings(channelIdx, preset)
  {
    API.graphql({query: updateRenderSettings, variables: {id: this.props.device.id, channelIdx: channelIdx ,newRenderSettings: preset.RenderSettings, expectedVersion:this.props.device._version}}).then(value => {
      this.props.refreshDevice();
      this.setState(ps=>{ps.renderSettingModalOpen[channelIdx] = false; return ps})
    }).catch(err=>
    {
      alert('Failed to set new version');
    })
  }

    handleChannelRename(channelIdx)
    {
        this.setState(ps=>{ps.editNameModalOpen[channelIdx] = !ps.editNameModalOpen[channelIdx]; return ps})
    }

    _handleNameChanged(e, channelIdx)
    {
        let names = this.state.channelNames;
        names[channelIdx] = e.target.value
        this.setState({channelNames: names})
    }

    _changeChannelName(channelIdx)
    {
        let name = this.state.channelNames[channelIdx];
        if(name && (name.length > 0 && name.length < 30))
        {
            console.log("Changing name to " + name)
            API.graphql({query: updateChannelName, variables: {id: this.props.device.id, channelIndex: channelIdx ,newName: name, expectedVersion:this.props.device._version}}).then(value => {
                this.props.refreshDevice();
                this.handleChannelRename(channelIdx);
            }).catch(err=>
            {
                alert('Failed to update channel name');
            })
        }
        else
        {
            alert("Name Invalid")
        }
    }

  ChannelDisplay = (props) =>
  {
    //{this.props.device.CurrentState.Channels.map((channel, idx) => (<this.channelDisplay device={this.props.device} channelIdx={idx}/>))}
    const {device, channelIdx} = props;
    const channel = device.PendingState.Channels[channelIdx];
    if(channel)
    {
      return (
          <div>
              <p className="m-0 my-1 mb-2">&nbsp; Channel {channelIdx}: {channel.Name}&nbsp;
                  <span className="text-success" onClick={() => this.handleChannelRename(channelIdx)}>
                      <i className="material-icons">edit</i>
                    </span
                    ></p>

              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; Type: {channel.Type}</p>
              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; Resolution: {channel.Resolution}dp</p>
              <p className="m-0 my-1 mb-2 text-muted">&nbsp; &nbsp; Unit: <abbr
                  title={channel.RenderSettings?("(" + channel.RenderSettings?.ScaleFactor??"1" + " x raw) + " + channel.RenderSettings?.Offset??"0" + " = val in " + channel.RenderSettings?.Unit??""):("Unit was setup before units had been implemented, it will function under the assumption it is deg C but if you resetup the unit it will adopt units formally")}>{channel.RenderSettings?.Unit??"Please resetup to enable units"}</abbr>
              </p>

              <ButtonGroup size="sm">
                  <Button theme="white" onClick={() => this.handleRenderSettingsChange(channelIdx)}>
                  <span className="text-success">
                    <i className="material-icons">edit</i>
                  </span>{" "}
                      Change Render Settings
                  </Button>
              </ButtonGroup>
              <Modal open={this.state.renderSettingModalOpen[channelIdx]}
                     toggle={() => this.handleRenderSettingsChange(channelIdx)}>
                  <ModalHeader>Change Render Settings</ModalHeader>
                  <ModalBody centered="true">
                      <p>Please select a preset from below</p>
                      <ButtonGroup vertical>
                          {RenderSettingPresets[device.CurrentState.Channels[channelIdx].Type].map((preset, idx) => (
                              <Button block key={idx} size="lg" theme="white"
                                      onClick={() => this.handleApplyNewRenderSettings(channelIdx, preset)}>
                                  {preset.Name}
                              </Button>))}
                      </ButtonGroup>
                  </ModalBody>
              </Modal>
              <Modal open={this.state.editNameModalOpen[channelIdx]}
                     toggle={() => this.handleChannelRename(channelIdx)}>
                  <ModalHeader>Rename Channel {channelIdx}</ModalHeader>
                  <ModalBody centered="true">
                      <Form>
                          <FormGroup>
                              <label htmlFor="#channel-name">Channel Name</label>
                              <FormInput id="#channel-name" placeholder={channel.Name} onChange={(e)=>this.handleNameChanged(e, channelIdx)} />
                          </FormGroup>
                          <FormGroup>
                              <Button onClick={()=>this.changeChannelName(channelIdx)}>Update Channel Name</Button>
                          </FormGroup>
                      </Form>
                  </ModalBody>
              </Modal>
          </div>)
    }
    else
    {
      return (<p className="m-0 my-1 mb-2 text-muted">Unknown Channel</p>)
    }
  }

  render() {
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Channels
          {this.props.device.CurrentState.Channels.map((channel, idx) => (<this.ChannelDisplay key={idx} device={this.props.device} channelIdx={idx}/>))}
        </div>
        <div className="blog-comments__actions">
        </div>
      </div>)
  }
}

const ChannelActions =
  {
    body: ChannelActionsMenu,
    title: "Channels"
  }

export default ChannelActions
