import {
    Button, Form, FormGroup, FormInput, Modal, ModalBody, ModalHeader
} from "shards-react";
import React from "react";
import {updateChannelName, updateDeviceName} from "../../../graphql/mutations";
import {API} from "aws-amplify";

class NameActionsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promptOpen: false,
            newName: this.props.Device?.Name ?? ""
        }
        this.handleNameEdit = this.handleNameEdit.bind(this);
        this.changeDeviceName = this.changeDeviceName.bind(this);
        this.handleNameChanged = this.handleNameChanged.bind(this);
    }


    handleNameEdit() {
        this.setState(ps => {
            ps.promptOpen = !ps.promptOpen;
            return ps
        })
    }


    changeDeviceName(newName) {

        console.log("Changing name to " + this.state.newName)
        API.graphql({query: updateDeviceName, variables: {id: this.props.device.id, newName: this.state.newName, expectedVersion:this.props.device._version}}).then(value => {
            this.props.refreshDevice();
            this.handleNameEdit();
        }).catch(err=>
        {
            alert('Failed to update channel name');
        })
    }


    handleNameChanged(e) {
        this.setState({newName: e.target.value})
    }

    render() {
        return (
            <div>
                <div className="blog-comments__meta text-mutes">
                    Name: {this.props.device.Name} <span className="text-success" onClick={this.handleNameEdit}>
                      <i className="material-icons">edit</i>
                    </span>
                </div>
                <Modal open={this.state.promptOpen}
                       toggle={this.handleNameEdit}>
                    <ModalHeader>Rename Device</ModalHeader>
                    <ModalBody centered="true">
                        <Form>
                            <FormGroup>
                                <label htmlFor="#channel-name">New Name</label>
                                <FormInput id="#channel-name" placeholder={this.props.device.Name}
                                           onChange={this.handleNameChanged}/>
                            </FormGroup>
                            <FormGroup>
                                <Button onClick={this.changeDeviceName}>Update Device Name</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>)
    }
}

const NameActions =
    {
        body: NameActionsMenu,
        title: "Name"
    }

export default NameActions
