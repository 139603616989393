import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import aws_exports from './aws-exports';
import Amplify, {Auth, Hub} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Constants, Dispatcher} from "./flux";
import {DefaultLayout} from "./layouts";

import Store from './flux/store'

/* Override the domain to use the custom domain version */
/* Original exports domain is of format <app name here>-<instance id>-<env>.auth.eu-west-1.amazoncognito.com */
/* Custom domain is of the format <env>.auth.wireless-alert.co.uk */
aws_exports.oauth.domain = aws_exports.oauth.domain.split('.')[0].split('-')[2] + ".auth.wireless-alert.co.uk";

Amplify.configure(aws_exports);

let generateSideBar = (user) => {
    Dispatcher.dispatch({
        actionType: Constants.SET_USER,
        payload: user
    });
    return Store.getSidebarItems()[0].to;
}

Hub.listen('auth', (data) => {
    switch (data.payload.event) {
        case 'signIn':
            Dispatcher.dispatch({
                actionType: Constants.SET_USER,
                payload: data.payload.data
            });
            console.log('user signed in');
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
    }
});

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            availableRoutes: Store.getSidebarItems()
        };
        Auth.currentAuthenticatedUser().then(user=>
        {
            Dispatcher.dispatch({
                actionType: Constants.SET_USER,
                payload: user
            });
        })

        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        Store.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onChange);
    }
    onChange() {
        this.setState({
            ...this.state,
            availableRoutes: Store.getSidebarItems()
        });
    }

    render()
    {
        console.log(JSON.stringify(this.state.availableRoutes))
        return (
            <Authenticator loginMechanisms={['username', 'email']} signUpAttributes={['email', 'username']}>
                {({signOut, user}) => (
                    <Router basename={process.env.REACT_APP_BASENAME || ""}>
                        <Switch>
                            <Route
                                path="/"
                                exact={true}
                                component={(props => {
                                    return (
                                        <DefaultLayout>
                                            <Redirect to={generateSideBar(user)} />
                                        </DefaultLayout>
                                    );
                                })}
                            />
                            {this.state.availableRoutes.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.to}
                                        exact={route.exact}
                                        component={(props => {
                                            return (
                                                <DefaultLayout {...props} user={user}>
                                                    <route.component {...props} user={user}/>
                                                </DefaultLayout>
                                            );
                                        })}
                                    />
                                );
                            })}
                            <Redirect from='*' to='/errors' />
                        </Switch>
                    </Router>
                )}
            </Authenticator>
        )
    }
}
export default App