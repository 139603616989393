
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

import {API} from 'aws-amplify';

const listDevicesMinimal = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MACAddress
        Name
        CurrentState {
          Type
        }
        Spectators
        Maintainers
        Tags
        owner
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

class OwnDevices extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      devices: [],
      activeDevices: [],
      loading: true
    };
  }



  componentDidMount() {
    API.graphql( {query: listDevicesMinimal, variables: {filter: {owner: {eq: this.props.user.username}}}}).then(value =>
    {
      let devices = value.data.listDevices.items;
      this.setState(prevState => ({
        devices: devices,
        activeDevices: devices.filter(dev=>!dev._deleted),
        loading: false
      }));
    }).catch(err=>
    {
      console.log("Graphql err: ", err);
    })
  }

  render() {

    const {
      activeDevices,
      loading
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title={"Your Devices " + (loading?"(loading)":("(" + activeDevices.length + " device" + (activeDevices.length>1?"s":"") + ")"))} subtitle="Devices"
                     className="text-sm-left"/>
        </Row>
        {/* First Row of Devices */}
        <Row>
          {activeDevices.map((dev, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{backgroundImage: `url(${require("../images/avatars/WA-Pro.png")})`}}
                >
                  <Badge
                    pill
                    className={`card-post__category `}
                  >
                    {dev.MACAddress}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <p
                    >
                      {dev.CurrentState.Type}
                    </p>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a href={ "/device/" + dev.id} className="text-fiord-blue">
                      {dev.Name?dev.Name:"Unnamed Device"}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">This device was setup at:</p>
                  <br/>
                  <span className="text-muted">{dev.createdAt}</span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
export default OwnDevices;
