import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col
} from "shards-react";

import {shortTimeFormatter} from "../../utils/formatters"

const EventIcons =
  {
    SETUP: require("../../images/event-icons/setup.png"),
    ALARM_ENTER: require("../../images/event-icons/alarm-enter.png"),
    ALARM_EXIT: require("../../images/event-icons/alarm-exit.png"),
    DIGEST: require("../../images/event-icons/digest.png"),
    DEFAULT: require("../../images/event-icons/unknown.png")
  }

  const EventBody = (props) =>
  {
    const event = props.event;
    let response;
    switch (event.Type) {
      case 'SETUP':
        response = (<p className="m-0 my-1 mb-2 text-muted">Auto generated event</p>);
        break;
      case 'ALARM_ENTER':
      case 'ALARM_EXIT':
        response = (<p className="m-0 my-1 mb-2 text-muted">Channel {event.Channel}, alarm setting: {event.AlarmSetting}</p>);
        break;
      case 'DIGEST':
        response = (<p className="m-0 my-1 mb-2 text-muted">Auto generated event</p>);
        break;
      default:
        response = (<p className="m-0 my-1 mb-2 text-muted">unknown</p>)
        break;
    }
    return response;
  }

const Events = ({title, events}) => (
  <Card small className="blog-comments">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="p-0">
      {events.sort((eventA, eventB)=>eventB.Timestamp-eventA.Timestamp).map((event, idx) => (
        <div key={idx} className="blog-comments__item d-flex p-3">
          {/* Avatar */}
          <div className="blog-comments__avatar mr-3">
            <img
              src={EventIcons[event.Type] ? EventIcons[event.Type] : EventIcons.DEFAULT}
              alt={""}/>
          </div>

          {/* Content */}
          <div className="blog-comments__content">
            {/* Content :: Title */}
            <div className="blog-comments__meta text-mutes">
              {event.Type}
              <span className="text-mutes"> - {shortTimeFormatter(event.Timestamp)}</span>
            </div>

            {/* Content :: Body */}
            <EventBody event={event}/>
          </div>
        </div>
      ))}
    </CardBody>

    <CardFooter className="border-top">
      <Row>
        <Col className="text-center view-report">
          <Button theme="white" type="submit">
            Previous
          </Button>
          <Button theme="white" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </CardFooter>
  </Card>
);

Events.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The discussions dataset.
   */
  events: PropTypes.array
};

Events.defaultProps = {
  title: "Events",
  events: []
};

export default Events;
