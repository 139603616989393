import {
  Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu
} from "shards-react";
import React from "react";
import {API, Storage} from 'aws-amplify';
import {updateDeviceFirmware } from "../../../graphql/mutations";

class FirmwareActionsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state =
      {
        availableVersions: [],
        selectedVersionIdx: 0,
        dropDownOpen: false,
      }
    this.handleResetToCurrent = this._handleResetToCurrent.bind(this);
    this.handleUpdateToLatest = this._handleUpdateToLatest.bind(this);
    this.handleSelectFirmware = this._handleSelectFirmware.bind(this);
    this.handleDropDownChange = this._handleDropDownChange.bind(this);
    this.handleDropDownToggle = this._handleDropDownToggle.bind(this);
  }

  fwVersion = (props) =>
  {
    if(props.device.CurrentState.FirmwareVersion)
    {
      if(props.device.CurrentState.FirmwareVersion === props.device.PendingState.FirmwareVersion)
      {
        return (
          <p className="m-0 my-1 mb-2 text-muted">
          Version: {props.device.CurrentState.FirmwareVersion}
          </p>)
      }
      else
      {
        return (<p className="m-0 my-1 mb-2 text-muted">
          Current Version: {props.device.CurrentState.FirmwareVersion}<br/>
          Pending Version: {props.device.PendingState.FirmwareVersion}
        </p>)
      }
    }
    else
    {
      return (<p className="m-0 my-1 mb-2 text-muted">
        Unknown firmware version
      </p>)
    }
  }

  componentDidMount() {
    let versions = [];

    Storage.list('ci/') // for listing ALL files without prefix, pass '' instead
      .then(result => {
        result = result.filter(file => null !== file.key.match(/^((\w|\.|-)+\/)+ota_app.bin$/g));
        result.forEach(file=>
        {
          let parts = file.key.split("/")
          versions.push({key: file.key, version: parts[1]});
        })
        this.setState(ps=>{return {availableVersions: versions}})
      })
      .catch(err => {
        console.log(err)
      });
  }

  _handleResetToCurrent()
  {
    if (window.confirm("Are you sure you accept the current version")) {
      API.graphql({query: updateDeviceFirmware, variables: {id: this.props.device.id, firmwareVersion: this.props.device.CurrentState.FirmwareVersion, expectedVersion:this.props.device._version}}).then(value => {
        console.log(value.data.getDevice);
        this.props.refreshDevice();
      }).catch(err=>
      {
        alert('Failed to set new version');
      })
    }
  }

  _handleUpdateToLatest()
  {
    let availableVersions = this.state.availableVersions;
    let latestVersionIdx =  availableVersions.reduce(function (newestIdx, next, index) {
      return next.version.substring(1) > availableVersions[newestIdx].version.substring(1) ? index : newestIdx;
    }, 0)
    console.log("Latest: ")
    console.log(availableVersions[latestVersionIdx])
    if (window.confirm("Are you sure you want to update to " + availableVersions[latestVersionIdx].version)) {
      API.graphql({query: updateDeviceFirmware, variables: {id: this.props.device.id, firmwareVersion: availableVersions[latestVersionIdx].version, expectedVersion:this.props.device._version}}).then(value => {
        console.log(value.data.getDevice);
        this.props.refreshDevice();
      }).catch(err=>
      {
        alert('Failed to set new version');
      })
    }
  }

  _handleSelectFirmware(versionIdx)
  {
    let availableVersions = this.state.availableVersions;
    let selectedVersionIdx = versionIdx;
    console.log("Selected " + this.state.selectedVersionIdx);
    if (window.confirm("Are you sure you want to update to " + availableVersions[selectedVersionIdx].version)) {
      API.graphql({query: updateDeviceFirmware, variables: {id: this.props.device.id, firmwareVersion: availableVersions[selectedVersionIdx].version, expectedVersion:this.props.device._version}}).then(value => {
        console.log(value.data.getDevice);
        this.props.refreshDevice();
      }).catch(err=>
      {
        alert('Failed to set new version');
      })
    }
  }

  _handleDropDownChange(event)
  {
    let selectedIdx = Number(event.target.value);
    this.setState(ps=>{return {selectedVersionIdx:selectedIdx}})
  }

  _handleDropDownToggle()
  {
    this.setState(ps=>{return {dropDownOpen:!ps.dropDownOpen}})
  }


  render() {
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Firmware
          <this.fwVersion device={this.props.device}/>
        </div>
        <div className="blog-comments__actions">
          <ButtonGroup size="sm">
            <Button theme="white" onClick={this.handleResetToCurrent}>
                  <span className="text-success">
                    <i className="material-icons">restart_alt</i>
                  </span>{" "}
              Reset To Current
            </Button>
            <Button theme="white" onClick={this.handleUpdateToLatest}>
                  <span className="text-success">
                    <i className="material-icons">update</i>
                  </span>{" "}
              Update To Latest
            </Button>
          </ButtonGroup>

          <Dropdown open={this.state.dropDownOpen} toggle={this.handleDropDownToggle}>
            <Button size="sm" theme="white" onClick={this.handleDropDownToggle}>
                  <span className="text-light">
                    <i className="material-icons">more_vert</i>
                  </span>{" "}
              Select Version Manually
            </Button>
            <DropdownMenu>
              {this.state.availableVersions.map((ver, idx)=>(<DropdownItem key={idx} onClick={()=>this.handleSelectFirmware(idx)}>{ver.version}</DropdownItem>))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>)
  }
}

const FirmwareActions =
  {
    body: FirmwareActionsMenu,
    title: "Firmware"
  }

export default FirmwareActions
