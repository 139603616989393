import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup
} from "shards-react";

import Chart from "../../utils/chart";
import moment from "moment";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChannel: 0,
      title: "Latest Readings",
      chartData: {
        datasets: [
          {
            label: "",
            fill: "start",
            data: [],
            backgroundColor: "rgba(0,123,255,0.1)",
            borderColor: "rgba(0,123,255,1)",
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "rgb(0,123,255)",
            borderWidth: 1.5,
            pointRadius: 0,
            pointHoverRadius: 3
          }
        ]
    }};
    this.handleChannelButton = this._handleChannelButton.bind(this);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartOptions = {
      responsive: true,
      legend: {
        position: "top"
      },
      elements: {
        line: {
          // A higher value makes the line look skewed at this ratio.
          tension: 0.3
        },
        point: {
          radius: 0
        }
      },
      scales: {
        xAxes: [
          {
            position: 'left',
            ticks: {
              callback: value => {
                let date = moment(value);
                return date.format('H:mm');
              },
              stepSize: 300
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback(tick) {
                if (tick === 0) {
                  return tick;
                }
                // Format the amounts using Ks for thousands.
                return `${(tick).toFixed(1)}°C`;
              }
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel + "xx";
          },
          title: function (tooltipItem) {
            let date = moment(Number(tooltipItem[0].label));
            return date.format('ddd, Do MMM YYYY, HH:mm:ss');
          }
        },
        custom: false,
        mode: "nearest",
        intersect: false
      }
    };

    const LatestReadingsGraph = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: this.state.chartData,
      options: chartOptions
    });
/*
    // They can still be triggered on hover.
    const buoMeta = LatestReadingsGraph.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
    this.state.chartData.datasets[0].data.length - 1
      ]._model.radius = 0;*/

    // Render the chart.

    this.loadChannelData(LatestReadingsGraph, 0);
    this.setState(ps=>
    {
      ps.LatestReadingsGraph = LatestReadingsGraph;
      return ps;
    })
  }

  loadChannelData(chart, channelIdx)
  {
    let channelReadings = this.props.device.LatestReadings[channelIdx].Readings.map(r=>
    {
      return {x:r.Timestamp,y:r.Value}
    }).reverse();
    chart.data.datasets[0].label = this.props.device.CurrentState.Channels[channelIdx].Name;
    chart.data.datasets[0].data = channelReadings
    chart.data.labels = channelReadings.map(cr=>cr.x*1000)
    let units = this.props.device.CurrentState.Channels[channelIdx].RenderSettings?.Unit??"";
    let resolution = this.props.device.CurrentState.Channels[channelIdx].Resolution;

    let maxValue = Math.max(...channelReadings.map(r=>r.y), ...this.props.device.CurrentState.Channels[channelIdx].AlarmSettings.map(as=>as.Level));
    let minValue = Math.min(...channelReadings.map(r=>r.y), ...this.props.device.CurrentState.Channels[channelIdx].AlarmSettings.map(as=>as.Level));
    let delta = maxValue-minValue;
    maxValue+= delta*0.1;
    minValue-= delta*0.1;
    chart.options =
      {
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        annotation: {
          drawTime: 'beforeDatasetsDraw',
          annotations: this.props.device.CurrentState.Channels[channelIdx].AlarmSettings.map(as => {
            return {
              id: 'a-line-2', // optional
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: as.Level,
              borderColor: 'red',
              borderWidth: 2,
              label: {
                backgroundColor: 'rgba(0,0,0,0.8)',
                fontFamily: "sans-serif",
                fontSize: 12,
                fontStyle: "bold",
                fontColor: "#fff",
                xPadding: 6,
                yPadding: 6,
                cornerRadius: 6,
                position: "center",
                enabled: true,
                content: as.Level.toFixed(resolution) + units
              },
            }
          })
        },
        scales: {
          xAxes: [
            {
              position: 'left',
              ticks: {
                callback: value => {
                  let date = moment(value);
                  return date.format('H:mm');
                },
                stepSize: 300
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: minValue,
                suggestedMax: maxValue,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return `${(tick).toFixed(resolution) + units}`;
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel + units;
            },

            // We'll edit the `title` string
            title: function (tooltipItem) {
              // `tooltipItem` is an object containing properties such as
              // the dataset and the index of the current item

              // Here, `this` is the char instance

              // The following returns the full string
              let date = moment(Number(tooltipItem[0].label));
              return date.format('ddd, Do MMM YYYY, HH:mm:ss');
            }
          },
          custom: false,
          mode: "nearest",
          intersect: false
        }
      }
    chart.update();
    this.setState(ps=>
    {
      ps.selectedChannel = channelIdx;
      return ps;
    })
  }

  _handleChannelButton(channelIdx) {
    this.loadChannelData(this.state.LatestReadingsGraph, channelIdx)
  }


  render() {
    const { title, selectedChannel } = this.state;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <ButtonGroup>
                {this.props.device.CurrentState.Channels.map((channel, channelIdx)=>
                {
                  if(channelIdx === selectedChannel)
                  {
                    return (<Button key={channelIdx} onClick={()=>this.handleChannelButton(channelIdx)}>{channel.Name}</Button>)
                  }
                  else
                  {
                    return (<Button key={channelIdx} outline onClick={()=>this.handleChannelButton(channelIdx)}>{channel.Name}</Button>)
                  }
                })}
              </ButtonGroup>
            </Col>
            <Col>
              <Button
                size="sm"
                className="d-flex btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
              >
                Placeholder &rarr;
              </Button>
            </Col>
          </Row>
          <canvas
            height="60"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

Graph.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

export default Graph;
