import React from "react";
import {Button, ButtonGroup, Modal, ModalHeader, ModalBody, FormGroup, FormInput, Form, FormSelect} from "shards-react";
import {API} from 'aws-amplify';
import {
    disableDigestEmails, enableDigestEmails, updateDigestSetting
} from "../../../graphql/mutations";
import moment from "moment";


class DigestActionsMenu extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          editScheduleOpen: [false],
          form:
              {
                  interval: 600
              }
      }
  }

    handleEditSchedule(digestIdx)
    {
        this.setState(ps=>
        {
            ps.editScheduleOpen[digestIdx] = !ps.editScheduleOpen[digestIdx];
            return ps;
        })
    }

    disableDigests()
    {
        API.graphql({query: disableDigestEmails, variables: {id: this.props.device.id, expectedVersion:this.props.device._version}}).then(value => {
            this.props.refreshDevice();
        }).catch(err=>
        {
            alert('Failed to update channel name');
        })
    }

    enableDigests()
    {
        API.graphql({query: enableDigestEmails, variables: {id: this.props.device.id, expectedVersion:this.props.device._version}}).then(value => {
            this.props.refreshDevice();
        }).catch(err=>
        {
            alert('Failed to update channel name');
        })
    }

    handleFormItemChange(e, fieldName)
    {
        let form = this.state.form;
        form[fieldName] = e.target.value;
        this.setState({form: form});
    }

    updateDigestSchedule(digestIdx)
    {
        console.log("Update to be every " + this.state.form.interval + " seconds")
        let newDigestSetting = this.props.device.CurrentState.DigestSettings[digestIdx];
        newDigestSetting.Schedule.Period = Number(this.state.form.interval);
        console.log(newDigestSetting)

        API.graphql({query: updateDigestSetting, variables: {id: this.props.device.id, index: digestIdx, digestSetting: newDigestSetting, expectedVersion:this.props.device._version}}).then(value => {
            this.props.refreshDevice();
            this.handleEditSchedule(digestIdx)
        }).catch(err=>
        {
            alert('Failed to update digest schedule');
        })
    }

  DigestSettingDisplay = (props) =>
  {
      const {device, digestIdx} = props;
      const schedule = this.props.device.CurrentState.DigestSettings[digestIdx].Schedule;
      const now = Date.now() / 1000;
      const nextDue = schedule.Datum + ((Math.floor(Math.abs(schedule.Datum - now)/schedule.Period) + 1) * schedule.Period)
      if(device.EmailSettings?.DigestEnabled)
      {
          return (
              <div>
                  <p className="m-0 my-1 mb-2 text-muted">Digests are enabled</p>
                  <p className="m-0 my-1 mb-2 text-muted">Interval: {this.renderDurationString(schedule.Period, true)}
                      {this.props.device.CurrentState.DigestSettings[digestIdx].Schedule.Period !== this.props.device.PendingState.DigestSettings[digestIdx].Schedule.Period?" (Change Pending)":""}</p>
                  <p className="m-0 my-1 mb-2 text-muted">Next due at: {moment(nextDue*1000).format('Do MMM YYYY, HH:mm:ss')}</p>
                  <ButtonGroup size="sm">
                      <Button theme="white" onClick={() => this.handleEditSchedule(digestIdx)}>
                  <span className="text-success">
                    <i className="material-icons">edit</i>
                  </span>{" "}
                          Edit Schedule
                      </Button>
                      <Button theme="white" onClick={() => this.disableDigests()}>
                  <span className="text-success">
                    <i className="material-icons">close</i>
                  </span>{" "}
                          Disable Digests
                      </Button>
                  </ButtonGroup>

                  <Modal open={this.state.editScheduleOpen[digestIdx]}
                         toggle={() => this.handleEditSchedule(digestIdx)}>
                      <ModalHeader>Edit Digest Schedule</ModalHeader>
                      <ModalBody centered="true">
                          <Form>
                              <FormGroup>
                                  <label htmlFor="#interval-select">Direction</label>
                                  <FormSelect id="#interval-select" value={this.state.form.interval} onChange={(e)=>this.handleFormItemChange(e, "interval")}>
                                      <option value="600">10 Minute</option>
                                      <option value="1800">30 Minute</option>
                                      <option value="3600">1 Hour</option>
                                      <option value="21600">6 Hour</option>
                                      <option value="43200">12 Hour</option>
                                      <option value="86400">1 Day</option>
                                      <option value="604800">7 Day</option>
                                  </FormSelect>
                              </FormGroup>
                              <FormGroup>
                                  <Button onClick={()=>this.updateDigestSchedule(digestIdx)}>Update Digest Schedule</Button>
                              </FormGroup>
                          </Form>
                      </ModalBody>
                  </Modal>
              </div>)
      }
      else
      {
          return (
              <div>
                  <p className="m-0 my-1 mb-2 text-muted">Digests are disabled</p>
                  <ButtonGroup size="sm">
                      <Button theme="white" onClick={() => this.enableDigests()}>
                  <span className="text-success">
                    <i className="material-icons">done</i>
                  </span>{" "}
                          Enable Digests
                      </Button>
                  </ButtonGroup>
              </div>)
      }
  }

  render() {
    return (
      <div>
        <div className="blog-comments__meta text-mutes">
          Digest
          {this.props.device.CurrentState.DigestSettings.map((channel, idx) => (<this.DigestSettingDisplay key={idx} device={this.props.device} digestIdx={idx}/>))}
        </div>
        <div className="blog-comments__actions">
        </div>
      </div>)
  }

    renderDurationString(duration, excludeZeroValues) {
        let output = "";
        /* duration initially in seconds */
        if (duration > 0) {
            if((false === excludeZeroValues) || ((duration % 60) > 0))
            {
                output = (duration % 60) + "s " + output
            }
            duration = Math.floor(duration / 60);
            /* duration now in minutes */
            if (duration > 0) {
                if((false === excludeZeroValues) || ((duration % 60) > 0))
                {
                    output = (duration % 60) + "m " + output
                }
                duration = Math.floor(duration / 60);
                /* duration now in hours */
                if (duration > 0) {
                    if((false === excludeZeroValues) || ((duration % 24) > 0))
                    {
                        output = (duration % 24) + "h " + output
                    }
                    duration = Math.floor(duration / 24);
                    /* duration now in days */
                    if (duration > 0) {
                        output = duration + "d " + output
                    }
                }
            }
        } else {
            output = "None"
        }
        return output.trim();
    };
}

const DigestActions =
  {
    body: DigestActionsMenu,
    title: "Digest"
  }

export default DigestActions
